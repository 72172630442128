import {
  Alert,
  BookOpen,
  CustomReports as CustomReportsIcon,
  Typography,
  useTranslation,
  Wrench,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { HelpAccordion } from "../_common/components/HelpAccordion";
import { getReportImpact } from "../_common/report-helpers/reportImpact";
import { getReportPriority } from "../_common/report-helpers/reportPriority";
import { Routes } from "../_common/routing/routes";
import { TopNavigation } from "../_common/top-navigation/TopNavigation";
import { ReportTypeCode } from "../graphql";
import { CrawlSettingsComparison } from "../report/report-rows/report-grid/rows/crawl-settings-comparison/CrawlSettingsComparison";
import { ReportCrawlSelector } from "../report/report-title/ReportCrawlSelector";
import { ReportEntity, ReportInput } from "../report/Report.types";
import { SegmentSelect } from "../segment-selector/SegmentSelect";
import {
  BaseReportTemplateDTO,
  CustomReportTemplateDTO,
} from "./_common/CustomReportTypes";
import { CustomReportFilterViewer } from "./CustomReportFilterViewer";
import { createPersistenceKeyFromReportInput } from "../report/Report.helpers";
import { getStoredColumnsParam } from "../_common/data-grid/column-persistance/columnsState";

type CustomReportHeaderProps = {
  title: string;
  accountId: string;
  projectId: string;
  crawlId: string;
  baseReportTemplate: BaseReportTemplateDTO;
  customReportTemplate: CustomReportTemplateDTO;
  reportAdviceCollapsed: boolean;
  isCustomReportTemplateDeleted: boolean;
  segmentId?: string;
};

function getIsGridView(storageKey: string): boolean {
  const value = localStorage.getItem(`${storageKey}_view_type`);
  if (!value) return false;

  return value === JSON.stringify("grid");
}

export const CustomReportHeader = (
  props: CustomReportHeaderProps,
): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation("customReports");
  const reportInput: ReportInput = {
    reportEntity: ReportEntity.CustomReport,
    crawlId: props.crawlId,
    customReportTemplateId: props.customReportTemplate.id,
    reportTypeCode: ReportTypeCode.Basic,
    reportTemplateCode: props.baseReportTemplate.code,
    segmentId: props.segmentId,
  };

  return (
    <>
      <TopNavigation
        title={props.title}
        leftComponent={
          <div className={classes.leftComponent}>
            <CustomReportsIcon className={classes.headerIcon} />
          </div>
        }
        actions={[
          <SegmentSelect key="segment-selector" />,
          <ReportCrawlSelector
            key="crawl-selector"
            reportInput={reportInput}
          />,
          <CrawlSettingsComparison key="crawl-settings-comparison" />,
        ]}
        breadcrumbs={[
          {
            label: t("pageTitle"),
            link: Routes.CustomReports.getUrl({
              accountId: props.accountId,
              projectId: props.projectId,
              crawlId: props.crawlId,
            }),
          },
          {
            label: props.title,
            link: Routes.CustomReport.getUrl({
              accountId: props.accountId,
              projectId: props.projectId,
              crawlId: props.crawlId,
              customReportTemplateId: props.customReportTemplate.id,
            }),
          },
        ]}
        availableForShare
        getShareLinkAdditionalParams={() => {
          const storageKey = createPersistenceKeyFromReportInput(reportInput);
          const isGridView = getIsGridView(storageKey);
          const columns = getStoredColumnsParam(storageKey, isGridView);

          return columns
            ? [
                `columns=${columns}`,
                `viewType=${isGridView ? "grid" : "table"}`,
              ]
            : [];
        }}
      />
      {props.isCustomReportTemplateDeleted ? (
        <Alert severity="warning" variant="standard" style={{ marginTop: 8 }}>
          {t("customReportDeletedAlert")}
        </Alert>
      ) : null}
      <HelpAccordion
        defaultCollapsed={props.reportAdviceCollapsed}
        priority={getReportPriority(props.customReportTemplate.totalWeight)}
        impact={getReportImpact(props.customReportTemplate.totalSign)}
        customReport={{
          templateId: props.customReportTemplate.rawID,
          baseReportName: props.baseReportTemplate.name,
          baseReportLink: Routes.Report.getUrl({
            accountId: props.accountId,
            projectId: props.projectId,
            crawlId: props.crawlId,
            reportTemplateCode: props.baseReportTemplate.code,
            reportTypeCode: "basic",
          }),
        }}
        entries={[
          {
            title: t("helpAccordion.reportDescriptionTitle"),
            content: props.customReportTemplate.description ?? "",
            icon: BookOpen,
          },
          {
            title: t("helpAccordion.reportContentTitle"),
            content: (
              <>
                <Typography className={classes.sectionDescription}>
                  {t("helpAccordion.reportContentDescription", {
                    baseReportName: props.baseReportTemplate.name,
                  })}
                </Typography>
                <div className={classes.filterContainer}>
                  <CustomReportFilterViewer
                    filter={props.customReportTemplate.filter}
                  />
                </div>
              </>
            ),
            icon: Wrench,
          },
        ]}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  leftComponent: {
    display: "flex",
    alignItems: "center",
  },
  headerIcon: {
    color: theme.palette.grey[600],
    fill: theme.palette.grey[400],
    marginRight: theme.spacing(1),
    marginTop: "4px",
  },
  sectionDescription: {
    color: theme.palette.grey[800],
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(22.5),
    whiteSpace: "pre-line",
    "& ol, ul": {
      paddingLeft: theme.spacing(2.75),
      margin: 0,
    },
    "& a": {
      color: theme.palette.blue[600],
      textDecoration: "none",
    },
    "& strong, b": {
      fontWeight: 600,
    },
  },
  filterContainer: {
    marginTop: theme.spacing(1.5),
  },
}));
