import React from "react";
import { useCrawlContextData } from "../../../../crawl-overview/CrawlContext";
import { useSearchParam } from "../../../routing/useSearchParam";
import { ChartConfigItem, TileChartConfigItem } from "../../types/ChartConfig";
import { isChartMissingRequiredSource } from "../utils";
import { ChartDataContext } from "./ChartDataContext";
import { getAggregatedMetric, getReportUnit } from "./chartDataHelpers";
import { orderBy } from "lodash";
import { getRawCrawlId, useQuery } from "@lumar/shared";
import { ChartConfigGenericReportStat } from "../../types/ChartConfigItemBase";
import * as Yup from "yup";
import { REPORT_STAT_TREND_ITEM_LIMIT } from "../../../constants";
import { useSelectedSegment } from "../../../../segment-selector/useSelectedSegment";
import { ReportTemplateUnit } from "../../../../graphql";
import merge from "deepmerge";

type ChartComponentProps = (ChartConfigItem | TileChartConfigItem) & {
  includeMultipleCrawlTypes?: boolean;
  noTrendsTemplate?: React.ReactElement;
  noReportsTemplate?: React.ReactElement;
  children?: React.ReactNode;
};

type ChartUsingCrawlContextComponentProps = (
  | ChartConfigItem
  | TileChartConfigItem
) & {
  inputSource: "CrawlContext";
  includeMultipleCrawlTypes?: boolean;
  noTrendsTemplate?: React.ReactElement;
  noReportsTemplate?: React.ReactElement;
  children?: React.ReactNode;
};

type ChartUsingGqlQueryComponentProps = (
  | ChartConfigItem
  | TileChartConfigItem
) & {
  inputSource: "GqlQuery";
  includeMultipleCrawlTypes?: boolean;
  noTrendsTemplate?: React.ReactElement;
  noReportsTemplate?: React.ReactElement;
  children?: React.ReactNode;
};

const REPORT_STATS_LIMIT_DEFAULT = 12;

export function ChartData(props: ChartComponentProps): JSX.Element | null {
  switch (props.inputSource) {
    case "CrawlContext":
      return <ChartDataUsingCrawlContext {...props} />;
    case "GqlQuery":
      return <ChartDataUsingGqlQuery {...props} />;
    default:
      return null;
  }
}

function ChartDataUsingCrawlContext(
  props: ChartUsingCrawlContextComponentProps,
): JSX.Element {
  const category = useSearchParam("category");
  const [segment] = useSelectedSegment();
  const { module, crawl, crawlProject, crawlReports } = useCrawlContextData();

  const isMissingRequiredSource = isChartMissingRequiredSource(
    props.requiredSources,
    crawl.crawlTypes,
  );

  const isChartUnavailable = isMissingRequiredSource;
  const totalUrls =
    crawlReports.find((report) => report.reportTemplateCode === "all_pages")
      ?.basic ?? 0;

  const relevantCrawlReports = crawlReports.filter(
    (stat) => props.reportStatFilter?.(stat, category) ?? true,
  );

  const reportTemplateCodesOrder = props.reportTemplateCodesOrder ?? [];

  const staticallyOrderedRelevantCrawlReports = reportTemplateCodesOrder
    .map((reportTemplateCode) =>
      relevantCrawlReports.find(
        (reportStat) => reportStat.reportTemplateCode === reportTemplateCode,
      ),
    )
    .filter((reportStat) => !!reportStat);

  const otherRelevantCrawlReports = relevantCrawlReports.filter(
    (reportStat) =>
      !reportTemplateCodesOrder.includes(reportStat.reportTemplateCode),
  );

  const reportStats = orderBy(
    [...staticallyOrderedRelevantCrawlReports, ...otherRelevantCrawlReports],
    props.reportStatsOrderBy?.field ?? [],
    props.reportStatsOrderBy?.direction ?? [],
  ).slice(0, props.reportStatsLimit ?? REPORT_STATS_LIMIT_DEFAULT);

  const getReportUnitCallback = React.useCallback(
    (report) => getReportUnit(report, module),
    [module],
  );

  const getAggregatedMetricCallback = React.useCallback(
    (report) => getAggregatedMetric(report, module),
    [module],
  );

  return (
    <ChartDataContext.Provider
      value={{
        inputSource: "CrawlContext",
        loading: false,
        error: undefined,
        isChartUnavailable,
        totalUrls,
        segmentName: segment?.name,
        crawl,
        project: crawlProject,
        reportStats,
        getReportUnit: getReportUnitCallback,
        getAggregatedMetric: getAggregatedMetricCallback,
      }}
    >
      {props.children}
    </ChartDataContext.Provider>
  );
}

function ChartDataUsingGqlQuery(
  props: ChartUsingGqlQueryComponentProps,
): JSX.Element {
  const category = useSearchParam("category");
  const crawlContext = useCrawlContextData();
  const segmentId = useSearchParam("segmentId");
  const { module, crawl, crawlReports, crawlProject } = crawlContext;
  const [segment] = useSelectedSegment();
  const isMissingRequiredSource = isChartMissingRequiredSource(
    props.requiredSources,
    crawl.crawlTypes,
  );

  const { data, error, loading } = useQuery(props.gqlDocument, {
    variables: props.gqlVariables({ crawlContext, category, segmentId }),
    fetchPolicy: "cache-first",
    skip: isMissingRequiredSource,
    context: {
      includeInBatch: true,
    },
  });

  const relevantCrawlReports = getReportStatsFromCustomQuery(data).filter(
    (stat) => props.reportStatFilter?.(stat, category) ?? true,
  );

  const reportTemplateCodesOrder = props.reportTemplateCodesOrder ?? [];

  const staticallyOrderedRelevantCrawlReports = reportTemplateCodesOrder
    .map((reportTemplateCode) =>
      relevantCrawlReports.find(
        (reportStat) => reportStat.reportTemplateCode === reportTemplateCode,
      ),
    )
    .filter((reportStat) => !!reportStat);

  const otherRelevantCrawlReports = relevantCrawlReports.filter(
    (reportStat) =>
      !reportTemplateCodesOrder.includes(reportStat.reportTemplateCode),
  );

  const reportStats = orderBy(
    [...staticallyOrderedRelevantCrawlReports, ...otherRelevantCrawlReports],
    props.reportStatsOrderBy?.field ?? [],
    props.reportStatsOrderBy?.direction ?? [],
  )
    .slice(0, props.reportStatsLimit ?? REPORT_STATS_LIMIT_DEFAULT)
    // Originally, the API returned 30 items. At the time of the writing,
    // the limit has been increased but the UI/UX had been designed around the 30 items limit in mind.
    // As a result, we are keeping this limit in the UI.
    .map(applyTrendLimitToReportStat);

  const isChartUnavailable = Boolean(isMissingRequiredSource || error);
  const totalUrls =
    crawlReports.find((report) => report.reportTemplateCode === "all_pages")
      ?.basic ?? 0;

  const getReportUnitCallback = React.useCallback(
    (report) => getReportUnit(report, module),
    [module],
  );

  const getAggregatedMetricCallback = React.useCallback(
    (report) => getAggregatedMetric(report, module),
    [module],
  );

  return (
    <ChartDataContext.Provider
      value={{
        inputSource: "GqlQuery",
        loading,
        error,
        isChartUnavailable,
        totalUrls,
        segmentName: segment?.name,
        crawl,
        project: crawlProject,
        reportStats,
        getReportUnit: getReportUnitCallback,
        getAggregatedMetric: getAggregatedMetricCallback,
      }}
    >
      {props.children}
    </ChartDataContext.Provider>
  );
}

// The intent is to create a set of guarantees to ensure
// that apollo cache will know how to store ReportStat/LegacyTask/CustomReport entities,
// parsers not having to worry about inexistence of certain fields
// and for custom query configs to not to have to check existence of commonly queried fields.
// Another intent is to make LegacyTask and CustomReport compatbile with existing historical abstraction.

const customQueryReportStatEntitySchema = Yup.object({
  crawlId: Yup.string().required(),
  segmentId: Yup.string().nullable().defined(),
  reportTemplateCode: Yup.string().required(),
  reportTemplateName: Yup.string().required(),
});

const singleReportStatQuerySchema = Yup.object({
  getReportStat: customQueryReportStatEntitySchema.required(),
});

const multipleReportStatsQuerySchema = Yup.object({
  getReportStats: Yup.array(customQueryReportStatEntitySchema).required(),
});

const reportStatsFromCrawlQuerySchema = Yup.object({
  getCrawl: Yup.object({
    id: Yup.string().required(),
    reportStats: Yup.array(customQueryReportStatEntitySchema).required(),
  }).required(),
});

const customQueryCustomReportEntitySchema = Yup.object({
  crawlId: Yup.string().required(),
  segmentId: Yup.string().nullable().defined(),
  customReportTemplate: Yup.object({
    id: Yup.string().required(),
    code: Yup.string().required(),
    name: Yup.string().required(),
    reportTemplate: Yup.object({
      code: Yup.string().required(),
      metadata: Yup.object({
        unit: Yup.string().nullable(),
      }),
    }).required(),
  }).required(),
});

const singleCustomReportQuerySchema = Yup.object({
  getCustomReport: customQueryCustomReportEntitySchema.required(),
});

const multipleCustomReportsQuerySchema = Yup.object({
  getCustomReports: Yup.array(customQueryCustomReportEntitySchema).required(),
});

const customReportsFromCrawlQuerySchema = Yup.object({
  getCrawl: Yup.object({
    id: Yup.string().required(),
    customReports: Yup.array(customQueryCustomReportEntitySchema).required(),
  }).required(),
});

const customQueryTaskEntitySchema = Yup.object({
  id: Yup.string().required(),
  title: Yup.string().required(),
  reportTemplate: Yup.object({
    code: Yup.string().required(),
    name: Yup.string().required(),
    metadata: Yup.object({
      unit: Yup.string().nullable(),
    }),
  })
    .nullable()
    .required(),
  customReports: Yup.array(customQueryCustomReportEntitySchema).nullable(),
  trend: Yup.array(
    Yup.object({
      crawlId: Yup.string().nullable(),
      identified: Yup.number().nullable(),
      crawlFinishedAt: Yup.string().nullable(), // FIXME: Remove after data migration (https://lumarhq.atlassian.net/browse/GA-2900) is done.
      crawlCreatedAt: Yup.string().nullable(),
    }),
  ).nullable(),
});

const singleTaskQuerySchema = Yup.object({
  getLegacyTask: customQueryTaskEntitySchema.required(),
});

function mapCustomReportToChartConfigGenericReportStat(
  customReport: ReturnType<
    typeof customQueryCustomReportEntitySchema.validateSync
  >,
): ChartConfigGenericReportStat {
  return merge(
    customReport,
    {
      reportTemplateCode: customReport.customReportTemplate.id,
      reportTemplateName: customReport.customReportTemplate.name,
      reportTemplate: {
        metadata: {
          unit: customReport.customReportTemplate.reportTemplate.metadata
            .unit as unknown as ReportTemplateUnit,
        },
      },
    },
    { arrayMerge: (_, sourceArray) => sourceArray },
  );
}

function mapTaskToChartConfigGenericReportStats(
  task: ReturnType<typeof customQueryTaskEntitySchema.validateSync>,
): ChartConfigGenericReportStat[] {
  // tagged task
  if (task.customReports) {
    return task.customReports.map((customReport) => {
      return merge(
        customReport,
        {
          reportTemplateCode: customReport.customReportTemplate.code,
          reportTemplateName: task.title,
          reportTemplate: {
            metadata: {
              unit: customReport.customReportTemplate.reportTemplate.metadata
                .unit as unknown as ReportTemplateUnit,
            },
          },
        },
        { arrayMerge: (_, sourceArray) => sourceArray },
      );
    });
  }

  // untagged task
  return [
    merge(
      task,
      {
        trend: task.trend
          ?.filter(
            (
              t,
            ): t is {
              crawlId: string;
              identified: number;
              crawlFinishedAt?: string;
              crawlCreatedAt?: string;
            } =>
              !!t.crawlId &&
              (!!t.crawlFinishedAt || !!t.crawlCreatedAt) &&
              typeof t.identified === "number",
          )
          .map((t) => {
            return {
              ...t,
              crawlId: Number(getRawCrawlId(t.crawlId)),
              basic: t.identified,
              createdAt: t.crawlCreatedAt ?? t.crawlFinishedAt,
              totalWeight: 0,
              queryVersion: 0,
            };
          }),
        reportTemplateCode: task.reportTemplate.code,
        reportTemplateName: task.title,
        reportTemplate: {
          metadata: {
            unit: task.reportTemplate.metadata
              .unit as unknown as ReportTemplateUnit,
          },
        },
      },
      { arrayMerge: (_, sourceArray) => sourceArray },
    ),
  ];
}

function getReportStatsFromCustomQuery(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: Record<string, any>,
): ChartConfigGenericReportStat[] {
  try {
    if (singleReportStatQuerySchema.isValidSync(data)) {
      return [data.getReportStat];
    } else if (multipleReportStatsQuerySchema.isValidSync(data)) {
      return data.getReportStats;
    } else if (reportStatsFromCrawlQuerySchema.isValidSync(data)) {
      return data.getCrawl.reportStats;
    } else if (singleCustomReportQuerySchema.isValidSync(data)) {
      return [
        mapCustomReportToChartConfigGenericReportStat(data.getCustomReport),
      ];
    } else if (multipleCustomReportsQuerySchema.isValidSync(data)) {
      return data.getCustomReports.map(
        mapCustomReportToChartConfigGenericReportStat,
      );
    } else if (customReportsFromCrawlQuerySchema.isValidSync(data)) {
      return data.getCrawl.customReports.map(
        mapCustomReportToChartConfigGenericReportStat,
      );
    } else if (singleTaskQuerySchema.isValidSync(data)) {
      return mapTaskToChartConfigGenericReportStats(data.getLegacyTask);
    }
  } catch (e) {
    console.error(e);
    return [];
  }

  return [];
}

function applyTrendLimitToReportStat(
  report: ChartConfigGenericReportStat,
): ChartConfigGenericReportStat {
  return merge<ChartConfigGenericReportStat>(
    report,
    {
      trend: report.trend?.slice(0, REPORT_STAT_TREND_ITEM_LIMIT),
    },
    { arrayMerge: (_, sourceArray) => sourceArray },
  );
}
