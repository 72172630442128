import { TileChartConfigItem } from "../types/ChartConfig";
import { TFunction } from "i18next";
import { GetAggregateChartDataDocument, ModuleCode } from "../../../graphql";

export function getSiteSpeedAvgCLSTileChart(
  t: TFunction<"charts">,
): TileChartConfigItem {
  return {
    inputSource: "GqlQuery",
    supportedModules: [ModuleCode.SiteSpeed],
    title: () => t("siteSpeedCLSMetricTrend.tile_title"),
    description: () => t("siteSpeedCLSMetricTrend.description"),
    unitDescription: t("unitDescription.layout"),
    icon: "lighthouse",
    gqlDocument: GetAggregateChartDataDocument,
    gqlVariables: ({ crawlContext, segmentId }) => {
      return {
        inputs: [
          {
            crawlId: crawlContext.crawl.rawID,
            segmentId,
            reportTemplateCode: "site_speed_pages:cumulative_layout_shift_avg",
          },
        ],
      };
    },
    testAttributePrefix: "cls-lighthouse",
  };
}
