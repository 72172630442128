import { useParams } from "react-router";
import { useSearchParam } from "../_common/routing/useSearchParam";
import {
  CrawlSegmentDataFragment,
  CrawlSegmentDataFragmentDoc,
} from "../graphql";
import { getApiCrawlId, useFragment, UseFragmentResult } from "@lumar/shared";

type ReturnedSegment =
  UseFragmentResult<CrawlSegmentDataFragment>["data"]["segment"];

/**
 * Helper hook allowing to quickly fetch selected segment with an intention to hit cache.
 * If you want segment id, then use `useSearchParam("segmentId")` instead.
 */
export function useSelectedSegment(): [
  ReturnedSegment,
  UseFragmentResult<CrawlSegmentDataFragment>,
] {
  const { crawlId } = useParams<{ crawlId: string }>();
  const segmentId = useSearchParam("segmentId");
  const fragment = useFragment<CrawlSegmentDataFragment>({
    fragment: CrawlSegmentDataFragmentDoc,
    from: {
      __typename: "CrawlSegment",
      // FIXME: Use crawlId and segmentId directly after https://lumarhq.atlassian.net/browse/UI-2873 is fixed.
      // Double-check if apollo cache stores hashed or raw ids.
      // crawlId: parseInt(crawlId) },
      // segmentId: parseInt(getRawSegmentId(segmentId ?? "")) },
      crawl: { id: getApiCrawlId(crawlId) },
      segment: { id: segmentId },
    },
  });
  return [fragment.data?.segment, fragment];
}
