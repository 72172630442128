import React from "react";
import { useTranslation } from "react-i18next";
import { FormikContextType } from "formik";
import { insertIf, useBrand, useSession } from "@lumar/shared";
import { TFunction } from "i18next";

import { ContextValues, FormValues } from "./data/types";
import { getDomainScopeControl } from "./scope/DomainScope";
import { getUrlScopeControl } from "./scope/UrlScope";
import { getResourceRestrictionsControl } from "./scope/ResourceRestrictions";
import { getLinkRestrictionsControl } from "./scope/LinkRestrictions";
import { getRedirectSettingsControl } from "./scope/RedirectSettings";
import { getLinkValidationControl } from "./scope/LinkValidation";
import { getStartUrlsControl } from "./spider-settings/StartUrls";
import { getJavaScriptRenderingControl } from "./spider-settings/JavaScriptRendering";
import { getCrawlerIpSettingsControl } from "./spider-settings/CrawlerIpSettings";
import { getUserAgentControl } from "./spider-settings/UserAgent";
import { getMobileSiteControl } from "./spider-settings/MobileSite";
import { getStealthModeControl } from "./spider-settings/StealthMode";
import { getCustomRequestHeadersControl } from "./spider-settings/CustomRequestHeaders";
import { getCustomExtractionControl } from "./extraction/CustomExtraction";
import { getRobotsOverwriteControl } from "./spider-settings/RobotsOverwrite";
import { getTestSiteDomainControl } from "./test-settings/TestSiteDomain";
import { getCustomDnsControl } from "./test-settings/CustomDns";
import { getAuthenticationControl } from "./test-settings/Authentication";
import { getRemoveUrlParametersControl } from "./test-settings/RemoveUrlParameters";
import { getUrlRewritingControl } from "./test-settings/UrlRewriting";
import { getApiCallbackControl } from "./report-setup/ApiCallback";
import { getEmailAlertsControl } from "./report-setup/EmailAlerts";
import { getReportSettingsControl } from "./report-setup/ReportSettings";
import { getCookiesControl } from "./spider-settings/Cookies";
import { ModuleCode } from "../../../graphql";
import { getSaveHTMLAndScreenshotsControl } from "./report-setup/SaveHTMLAndScreenshots";
import { getCrawlSafeguardControl } from "./spider-settings/CrawlSafeguard";

export interface SettingControl {
  title: string;
  path: string;
  control: (
    formikContext: FormikContextType<FormValues>,
    contextValues: ContextValues,
  ) => JSX.Element;
  testId: string;
  disabled?: (
    formikContext: FormikContextType<FormValues>,
    contextValues: ContextValues,
  ) => boolean;
  disabledTooltip?: string;
  isNew?: boolean;
  activeValues?: string[];
  openOnInitValues?: string[];
  /**
   * Text content of the setting that is used for advanced settings search.
   */
  textForSearch: string;
  fieldNames?: [string, string][];
}

export interface SettingsGroup {
  title: string;
  path: string;
  controls: SettingControl[];
}

export type SettingControlFactory = (props: {
  t: TFunction<"crawlSettings">;
  createTextForSearch: (texts: string[]) => string;
}) => SettingControl;

const createTextForSearch = (texts: string[]): string =>
  texts
    .join(" ")
    .toLocaleLowerCase()
    // Remove all i18n interpolation key and convert to lowercase.
    .replace(/({{[^{}]*}})|(<[^<>]*>)/g, "");

export function useAdvancedControls(moduleCode: ModuleCode): SettingsGroup[] {
  const { t } = useTranslation("crawlSettings");
  const { hasFeatureFlagEnabled } = useSession();
  const brand = useBrand();

  return React.useMemo(() => {
    const factoryProps = {
      t,
      createTextForSearch,
    };

    return [
      {
        title: t("settings.scope"),
        path: "scope",
        controls: [
          getDomainScopeControl(factoryProps),
          getUrlScopeControl(factoryProps),
          getResourceRestrictionsControl(factoryProps),
          getLinkRestrictionsControl(factoryProps),
          getRedirectSettingsControl(factoryProps),
          getLinkValidationControl(factoryProps),
        ],
      },
      {
        title: t("settings.spiderSettings"),
        path: "spider",
        controls: [
          getStartUrlsControl(factoryProps),
          getJavaScriptRenderingControl(factoryProps),
          getCrawlerIpSettingsControl(factoryProps),
          getUserAgentControl(factoryProps),
          ...insertIf(
            moduleCode !== ModuleCode.SiteSpeed,
            getMobileSiteControl(factoryProps),
          ),
          getRobotsOverwriteControl(factoryProps),
          getStealthModeControl(factoryProps),
          getCustomRequestHeadersControl(factoryProps),
          getCookiesControl(factoryProps),
          ...insertIf(
            hasFeatureFlagEnabled("crawl-safeguard"),
            getCrawlSafeguardControl(factoryProps),
          ),
        ],
      },
      {
        title: t("settings.extraction"),
        path: "extraction",
        controls: [getCustomExtractionControl(factoryProps)],
      },
      {
        title: t("settings.testSettings"),
        path: "test",
        controls: [
          getTestSiteDomainControl(factoryProps),
          getCustomDnsControl(factoryProps),
          getAuthenticationControl(factoryProps),
          getRemoveUrlParametersControl(factoryProps),
          getUrlRewritingControl(factoryProps),
        ],
      },
      {
        title: t("settings.reportSetup"),
        path: "report",
        controls: [
          ...insertIf(
            moduleCode === ModuleCode.Seo &&
              hasFeatureFlagEnabled("settings-save-html-screenshots"),
            getSaveHTMLAndScreenshotsControl(factoryProps),
          ),
          getApiCallbackControl(factoryProps),
          ...insertIf(
            brand.featureAvailability.crawlEmailAlerts,
            getEmailAlertsControl(factoryProps),
          ),
          getReportSettingsControl(factoryProps),
        ],
      },
    ];
  }, [
    brand.featureAvailability.crawlEmailAlerts,
    moduleCode,
    t,
    hasFeatureFlagEnabled,
  ]);
}
