import {
  Button,
  ComboBox,
  InformationCircleOutlined,
  insertIf,
  Snackbar,
  TextField,
  Toggle,
  Typography,
  useNumberFormatter,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { makeStyles, Tooltip } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { SnackbarKey, useSnackbar } from "notistack";
import { ReactNode, useState } from "react";
import { useParams } from "react-router";
import {
  CrawlType,
  RunningCrawlQuery,
  useUpdateProjectMutation,
} from "../../../graphql";
import {
  isCrawlRateValid,
  useCrawlRateSuggestions,
} from "../../crawl-settings/limits/types";
import { RunningCrawl } from "../../useProjectPoller";
import { ProgressControlsContainer } from "../_common/progress-controls-container/ProgressControlsContainer";
import { AdvancedSettingsButton } from "./advanced-settings-button/AdvancedSettingsButton";

const useStyles = makeStyles((theme) => ({
  indent: { marginBottom: theme.spacing(2) },
  formField: { marginBottom: 15 },
  speedDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
  safeguardDiv: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
  },
  label: {
    marginTop: theme.spacing(1),
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
  },
  info: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    color: theme.palette.grey[700],
  },
  tooltip: {
    verticalAlign: "middle",
    marginLeft: 12.5,
    color: theme.palette.grey[500],
  },
}));

interface Props {
  project?: RunningCrawlQuery;
  runningCrawl?: RunningCrawl;
}

export function RunningCrawlSettings({
  project,
  runningCrawl,
}: Props): JSX.Element {
  const classes = useStyles();
  const formatter = useNumberFormatter();
  const pagesLimitOptions = [10, 100, 1000, 10000, 100000, 1000000, 10000000];
  const { projectId } = useParams<{ projectId: string }>();
  const { account, hasFeatureFlagEnabled } = useSession();

  const restrictedRate = runningCrawl?.activeMaxCrawlRate;
  const isStealthModeEnabled = project?.getProject?.useStealthMode;
  const crawlSafeguardEnabled =
    runningCrawl?.crawlSafeguardEnabled ??
    project?.getProject?.failureRateLimitEnabled;

  const hasWeb = project?.getProject?.crawlTypes?.includes(CrawlType.Web);
  const [isSaving, setIsSaving] = useState(false);
  const [state, setState] = useState({
    autoFinalizeOnCrawlLimits:
      project?.getProject?.autoFinalizeOnCrawlLimits ?? false,
    maximumCrawlRate: project?.getProject?.maximumCrawlRate?.toString(),
    ...(hasWeb && { limitLevelsMax: project?.getProject?.limitLevelsMax }),
    limitUrlsMax: project?.getProject?.limitUrlsMax,
    violations: {
      maxCrawlRate: false,
      limitLevelsMax: false,
      limitPagesMax: false,
    },
    crawlSafeguardEnabled: crawlSafeguardEnabled ?? false,
  });

  const [save] = useUpdateProjectMutation();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("crawlProgress");
  const showSnack = (t: ReactNode, role?: "error" | "success"): SnackbarKey => {
    return enqueueSnackbar(<Snackbar variant={role ?? "success"} title={t} />);
  };

  const getComboBoxValue = (
    value?: string | number | unknown,
  ): number | undefined => {
    return value ? Number(`${value}`.replaceAll(",", "")) : undefined;
  };

  const stealthModeInfo = (
    <div style={{ marginBottom: "16px" }}>
      <div className={classes.speedDiv}>
        <Typography className={classes.label}>
          {t("crawlSettings.speedSliderLabel")}
        </Typography>
        <Typography className={classes.label}>
          {t("crawlSettings.speedWithStealthCrawl")}
        </Typography>
      </div>
      <Typography className={classes.info}>
        {t("crawlSettings.stealthCrawlEnabled")}
      </Typography>
    </div>
  );

  const crawlRateSuggestion = useCrawlRateSuggestions();

  return (
    <ProgressControlsContainer header={t("crawlSettings.header")}>
      {!isStealthModeEnabled ? (
        <>
          <Tooltip
            title={
              restrictedRate
                ? t("crawlSettings.speedSliderRestriction", {
                    crawlRate: restrictedRate,
                  })
                : ""
            }
          >
            <div className={classes.formField}>
              <Autocomplete
                value={state.maximumCrawlRate}
                onInputChange={(_, value) => {
                  setState((curr) => ({
                    ...curr,
                    maximumCrawlRate: value,
                    violations: {
                      ...curr.violations,
                      maxCrawlRate: !isCrawlRateValid(
                        Number(value),
                        account.maxCrawlRate,
                      ),
                    },
                  }));
                }}
                options={crawlRateSuggestion}
                filterOptions={(items) => items}
                freeSolo
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    ref={params.InputProps.ref}
                    label={t("crawlSettings.speedSliderLabel")}
                    inputProps={{
                      ...params.inputProps,
                      type: "number",
                      min: "0",
                      max: account.maxCrawlRate.toString(),
                    }}
                    error={state.violations.maxCrawlRate}
                    helperText={
                      state.violations.maxCrawlRate
                        ? t("validationErrors.maxCrawlRate", {
                            value: account.maxCrawlRate,
                          })
                        : ""
                    }
                    disabled={!!restrictedRate}
                  />
                )}
              />
            </div>
          </Tooltip>
          {hasWeb ? (
            <TextField
              className={classes.formField}
              id="crawlLevelTextField"
              label={t("crawlSettings.crawlDepthInputLabel")}
              type="number"
              value={state.limitLevelsMax}
              error={state.violations.limitLevelsMax}
              data-testid="crawl-level-textfield"
              helperText={
                state.violations.limitLevelsMax
                  ? t("validationErrors.limitLevelsMax", {
                      value: account.limitLevelsMax,
                    })
                  : ""
              }
              onChange={(e) =>
                setState((curr) => ({
                  ...curr,
                  limitLevelsMax: e.target.value
                    ? Number(e.target.value)
                    : undefined,
                  violations: {
                    ...curr.violations,
                    limitLevelsMax:
                      Number(e.target.value) > account.limitLevelsMax ||
                      Number(e.target.value) < 1,
                  },
                }))
              }
            />
          ) : null}
        </>
      ) : (
        stealthModeInfo
      )}
      <ComboBox
        freeSolo
        autoSelect
        className={classes.formField}
        getOptionLabel={(option) => {
          const value = getComboBoxValue(option);
          return formatter(value);
        }}
        options={pagesLimitOptions.filter((o) => o <= account.limitPagesMax)}
        value={state.limitUrlsMax}
        onChange={(_e, v) => {
          const value = getComboBoxValue(v);
          return setState((current) => ({
            ...current,
            limitUrlsMax: value,
            violations: {
              ...current.violations,
              limitPagesMax:
                value === 0 || (value ?? 0) > account.limitPagesMax,
            },
          }));
        }}
        inputProps={{
          label: t("crawlSettings.pagesLimitInputLabel"),
          helperText: state.violations.limitPagesMax
            ? t("validationErrors.limitPagesMax", {
                value: account.limitPagesMax,
              })
            : "",
          error: state.violations.limitPagesMax,
          InputLabelProps: {
            shrink: false,
          },
        }}
        data-testid="pages-limit-combobox"
      />
      <div className={classes.formField}>
        <Toggle
          label={t("crawlSettings.autofinalizeSwitchLabel")}
          id="crawlProgressSettingsAutoFinaliseToggle"
          checked={state.autoFinalizeOnCrawlLimits}
          onChange={(_, value) =>
            setState((curr) => ({ ...curr, autoFinalizeOnCrawlLimits: value }))
          }
          data-testid="finalize-when-limits-reached-toggle"
        />
      </div>
      {hasFeatureFlagEnabled("crawl-safeguard") ? (
        <div className={classes.safeguardDiv}>
          <Toggle
            label={t("crawlSettings.crawlSafeguardSwitchLabel")}
            id="crawlSafeguardToggle"
            checked={state.crawlSafeguardEnabled}
            onChange={(_, value) =>
              setState((curr) => ({ ...curr, crawlSafeguardEnabled: value }))
            }
            data-testid="crawl-safeguard-toggle"
          />
          <Tooltip
            title={t("crawlSettings.crawlSafeguardTooltip")}
            placement="right"
            className={classes.tooltip}
          >
            <InformationCircleOutlined />
          </Tooltip>
        </div>
      ) : null}
      <Button
        loading={isSaving}
        variant="contained"
        color="primary"
        disabled={
          !!Object.entries(state.violations).find(([, v]) => v === true)
        }
        fullWidth
        onClick={async () => {
          try {
            setIsSaving(true);
            await save({
              variables: {
                ...state,
                projectId,
                maximumCrawlRate: state.maximumCrawlRate
                  ? parseFloat(state.maximumCrawlRate)
                  : undefined,
                ...insertIf(hasFeatureFlagEnabled("crawl-safeguard"), {
                  failureRateLimitEnabled: state.crawlSafeguardEnabled,
                }),
              },
              refetchQueries: ["RunningCrawl"],
            });
            showSnack(t("crawlSettings.savedSettingsMessage"));
          } catch (e) {
            showSnack((e as Error).message, "error");
          } finally {
            setIsSaving(false);
          }
        }}
        data-testid="update-settings-button"
      >
        {t("crawlSettings.saveSettingsButtonText")}
      </Button>
      <AdvancedSettingsButton />
    </ProgressControlsContainer>
  );
}
