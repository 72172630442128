import { useTranslation } from "@lumar/shared";
import Highcharts, { Options } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ProgressDashboardContainer } from "../_common/progress-dashboard-container/ProgressDashboardContainer";

export function CrawlReponseCodesChart({
  responseCodesChartOptions,
}: {
  responseCodesChartOptions: Options;
}): JSX.Element {
  const { t } = useTranslation("crawlProgress");

  return (
    <ProgressDashboardContainer
      headerTitle={t("progressDashboard.crawlResponseCodesChartTitle")}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={responseCodesChartOptions}
        containerProps={{
          "data-testid": "crawl-response-codes-chart",
        }}
      />
    </ProgressDashboardContainer>
  );
}
