import { fieldToTextField, TextField, useTranslation } from "@lumar/shared";
import { Field, FieldProps } from "formik";
import { Label } from "./Label";
import ReactMarkdown from "react-markdown";
import RehypeHighlight from "rehype-highlight";
import { makeStyles } from "@material-ui/core";
import isUrl from "validator/lib/isURL";
import { renderToString } from "react-dom/server";

export function HowToFixField({
  isEditing,
}: {
  isEditing: boolean;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("taskManager");

  return (
    <Field name="howToFix">
      {(props: FieldProps<string>) => {
        const withCodeHighlightAndLinks = renderToString(
          <ReactMarkdown rehypePlugins={[RehypeHighlight]}>
            {props.field.value || "\\-"}
          </ReactMarkdown>,
        ).replace(/[^\s]+/g, (word) => {
          return isUrl(word)
            ? `<a href="${word}" rel="noreferrer" target="_blank">${word}</a>`
            : word;
        });

        return !isEditing ? (
          <>
            <Label label={t("editDialog.howToFixLabel")} />
            <div
              data-testid="task-dialog-how-to-fix"
              className={classes.howToFix}
              dangerouslySetInnerHTML={{
                __html: withCodeHighlightAndLinks,
              }}
            ></div>
          </>
        ) : (
          <TextField
            {...fieldToTextField(props)}
            id="edit-task-how-to-fix"
            label={t("editDialog.howToFixLabel")}
            placeholder={t("editDialog.howToFixPlaceholder")}
            disabled={props.form.isSubmitting}
            multiline
            maxRows={7}
            minRows={7}
            className={classes.input}
            InputLabelProps={{ className: classes.label }}
            data-testid="edit-task-how-to-fix"
          />
        );
      }}
    </Field>
  );
}

const useStyles = makeStyles((theme) => ({
  howToFix: {
    flexBasis: 0,
    flexGrow: 1,
    whitespace: "pre-line",
    lineHeight: theme.typography.pxToRem(20),
    minHeight: theme.typography.pxToRem(120),
    overflow: "auto",
    fontSize: theme.typography.pxToRem(14),
    "& code": {
      borderRadius: 8,
    },
  },
  input: {
    width: "100%",
    margin: theme.spacing(0, 0, 2, 0),
  },
  label: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
  },
}));
