import { TileChartConfigItem } from "../types/ChartConfig";
import { TFunction } from "i18next";
import { GetAggregateChartDataDocument, ModuleCode } from "../../../graphql";

export function getSiteSpeedAvgCruxCLSTileChart(
  t: TFunction<"charts">,
): TileChartConfigItem {
  return {
    unitDescription: t("unitDescription.layout"),
    icon: "crux",
    supportedModules: [ModuleCode.SiteSpeed],
    title: () => t("siteSpeedCruxCLSMetricTrend.tile_title"),
    description: () => t("siteSpeedCruxCLSMetricTrend.description"),
    descriptionTitle: () =>
      t("siteSpeedCruxCLSMetricTrend.tile_descriptionTitle"),
    noDataMessage: t("noCruxData"),
    inputSource: "GqlQuery",
    gqlDocument: GetAggregateChartDataDocument,
    gqlVariables: ({ crawlContext, segmentId }) => {
      return {
        inputs: [
          {
            crawlId: crawlContext.crawl.rawID,
            segmentId,
            reportTemplateCode: "crux_domain_summary:crux_cls_weighted_avg",
          },
        ],
      };
    },
  };
}
