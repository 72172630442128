import { TileChartConfigItem } from "../types/ChartConfig";
import { TFunction } from "i18next";
import { GetAggregateChartDataDocument, ModuleCode } from "../../../graphql";

export function getSiteSpeedAvgFCPTileChart(
  t: TFunction<"charts">,
): TileChartConfigItem {
  return {
    inputSource: "GqlQuery",
    supportedModules: [ModuleCode.SiteSpeed],
    unitDescription: t("unitDescription.speed"),
    icon: "lighthouse",
    title: () => t("siteSpeedFCPMetricTrend.tile_title"),
    description: () => t("siteSpeedFCPMetricTrend.description"),
    gqlDocument: GetAggregateChartDataDocument,
    gqlVariables: ({ crawlContext, segmentId }) => {
      return {
        inputs: [
          {
            crawlId: crawlContext.crawl.rawID,
            segmentId,
            reportTemplateCode: "site_speed_pages:first_contentful_paint_avg",
          },
        ],
      };
    },
    testAttributePrefix: "fcp-lighthouse",
  };
}
