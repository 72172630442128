import React from "react";
import {
  CircularProgress,
  Divider,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { Routes } from "../_common/routing/routes";
import { useURLSearchParams } from "../_common/routing/useURLSearchParams";
import {
  ChartPieSolid,
  ExclamationCircleOutlined,
  PencilSolid,
  Typography,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import { v4 as uuid } from "uuid";
import { GetCrawlSegmentsQuery, useGetCrawlSegmentsQuery } from "../graphql";
import { CustomSkeleton } from "../_common/CustomSkeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    minWidth: 230,
    width: "100%",
  },
  paper: {
    padding: 0,
  },
  listbox: {
    padding: 0,
  },
  groupHeader: {
    zIndex: 1,
    display: "flex",
    justifyContent: "space-between",
    position: "sticky",
    top: -8,
    padding: "10px 16px 10px 16px",
    background: theme.palette.grey[100],
    color: theme.palette.grey[700],
  },
  list: {
    padding: theme.spacing(1),
  },
  option: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  allUrlsIcon: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.grey[500],
    marginRight: theme.spacing(1.5),
  },
  optionContent: {
    width: "100%",
    display: "flex",
  },
  errorIcon: {
    marginLeft: "auto",
    fontSize: theme.typography.pxToRem(20),
  },
  loadingIcon: {
    marginLeft: "auto",
  },
  manageSegments: {
    color: theme.palette.primary.main,
  },
  manageSegmentsIcon: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1.5),
  },
  inputRoot: {
    "&[class*='MuiOutlinedInput-root'] $endAdornment": {
      right: 8,
    },
  },
  endAdornment: {},
}));

const allUrlsGroupId = uuid();

export function SegmentSelect(): JSX.Element {
  const { t } = useTranslation("segments");
  const history = useHistory();
  const classes = useStyles();
  const searchParams = useURLSearchParams();
  const selectedSegmentId = searchParams.get("segmentId");
  const session = useSession();
  const { accountId, projectId, crawlId } = useParams<{
    accountId: string;
    projectId: string;
    crawlId: string;
  }>();
  const [opened, setOpened] = React.useState(false);

  const crawlSegmentsPageResult = useGetCrawlSegmentsQuery({
    variables: { crawlId },
    fetchPolicy: "cache-first",
    skip: !session.hasAddon("segmentation"),
    notifyOnNetworkStatusChange: true,
  });

  const crawlSegments =
    crawlSegmentsPageResult.data?.getCrawl?.crawlSegments?.edges.map(
      (edge) => edge.node,
    ) ?? [];

  const crawlSegmentsBeingGeneratedIds = crawlSegments
    .filter((x) => !x.generatedAt && !x.failedAt)
    // FIXME: Use `segmentId` directly after https://lumarhq.atlassian.net/browse/GA-2975 is fixed.
    .map((x) => x.segment.id);

  const {
    startPolling,
    stopPolling,
    error: pollingError,
  } = useGetCrawlSegmentsQuery({
    variables: {
      crawlId,
      filter: { segmentId: { in: crawlSegmentsBeingGeneratedIds } },
    },
    fetchPolicy: "cache-first",
    onError: () => {
      stopPolling();
    },
    skip: crawlSegmentsBeingGeneratedIds.length === 0,
  });

  React.useEffect(() => {
    if (crawlSegmentsBeingGeneratedIds.length > 0 && !pollingError) {
      startPolling(60000);
      return () => stopPolling();
    }
  }, [
    crawlSegmentsBeingGeneratedIds.length,
    startPolling,
    stopPolling,
    pollingError,
  ]);

  const hasNextPage =
    crawlSegmentsPageResult.data?.getCrawl?.crawlSegments?.pageInfo.hasNextPage;
  const endCursor =
    crawlSegmentsPageResult.data?.getCrawl?.crawlSegments?.pageInfo.endCursor;

  if (hasNextPage && endCursor) {
    crawlSegmentsPageResult.fetchMore({
      variables: { crawlId, cursor: endCursor },
    });
  }

  const formattedSegments = crawlSegments.map((crawlSegment) => {
    const failedAt = crawlSegment.failedAt
      ? new Date(crawlSegment.failedAt).getTime()
      : 0;
    const generatingAt = crawlSegment.generatingAt
      ? new Date(crawlSegment.generatingAt).getTime()
      : 0;
    const generatedAt = crawlSegment.generatedAt
      ? new Date(crawlSegment.generatedAt).getTime()
      : 0;

    const isFailed = failedAt > generatingAt;
    const isGenerating = !generatedAt || generatedAt < generatingAt;
    return { ...crawlSegment, isGenerating, isFailed };
  });

  const options: SegmentOption[] = [
    { isAllUrls: true, label: t("segmentSelect.allUrls") },
    ...formattedSegments,
    { isManageSegments: true, label: t("manageSegments") },
  ];

  const selectedOption =
    formattedSegments.find((x) => x.segment.id === selectedSegmentId) ??
    options[0];

  const sortedOptions = options.toSorted(sortCrawlSegments);

  if (crawlSegmentsPageResult.loading) {
    return (
      <CustomSkeleton
        className={classes.root}
        animation="wave"
        variant="rect"
        height="36px"
      />
    );
  }

  return (
    <Autocomplete
      onOpen={() => !opened && setOpened(true)}
      value={selectedOption}
      loading={crawlSegmentsPageResult.loading}
      onChange={(_, option) => {
        if (option?.isAllUrls || !option) {
          searchParams.delete("segmentId");
          searchParams.delete("pagination");
          searchParams.delete("filter");
          history.push({ search: searchParams.toString() });
          return;
        }

        if (option.isManageSegments) {
          history.push(
            Routes.SegmentManager.getUrl({
              accountId,
              projectId,
              crawlId,
            }),
          );
          return;
        }

        searchParams.set("segmentId", option.segment.id);
        searchParams.delete("pagination");
        searchParams.delete("filter");
        history.push({ search: searchParams.toString() });
      }}
      options={crawlSegmentsPageResult.loading ? [] : sortedOptions}
      getOptionLabel={(option) => {
        if (option.isAllUrls || option.isManageSegments) {
          return option.label;
        }
        return option.segment.name;
      }}
      groupBy={(option) => {
        if (option.isAllUrls) {
          return allUrlsGroupId;
        }
        if (option.isManageSegments) {
          return "";
        }
        return option.segment.group ?? t("defaultGroup");
      }}
      getOptionDisabled={(option) => {
        if (option.isAllUrls || option.isManageSegments) {
          return false;
        }
        return option.isGenerating || option.isFailed;
      }}
      filterOptions={(options, { inputValue }) =>
        options.filter((o) =>
          o.isManageSegments
            ? true
            : (o.isAllUrls ? o.label : o.segment.name)
                .toLocaleLowerCase()
                .includes(inputValue.toLocaleLowerCase()),
        )
      }
      disableClearable={!selectedOption}
      renderOption={(option) => {
        if (option.isAllUrls) {
          return (
            <div className={classes.optionContent}>
              <ChartPieSolid className={classes.allUrlsIcon} />
              {option.label}
            </div>
          );
        }

        if (option.isManageSegments) {
          return (
            <div
              className={clsx(classes.optionContent, classes.manageSegments)}
              data-testid="segments-select-manage-button"
            >
              <PencilSolid className={classes.manageSegmentsIcon} />
              {option.label}
            </div>
          );
        }

        return (
          <div className={classes.optionContent}>
            {option.segment.name}
            {option.isGenerating && (
              <CircularProgress size={18} className={classes.loadingIcon} />
            )}
            {option.isFailed && (
              <ExclamationCircleOutlined
                color="error"
                className={classes.errorIcon}
              />
            )}
          </div>
        );
      }}
      renderGroup={({ key, group, children }) => (
        <li key={key}>
          {Boolean(group) && group !== allUrlsGroupId && (
            <div className={classes.groupHeader}>
              <Typography variant="subtitle3SemiBold">{group}</Typography>
            </div>
          )}
          {Boolean(!group && Number(key) !== 0) && <Divider />}
          <ul className={classes.list}>{children}</ul>
        </li>
      )}
      renderInput={(params) => <TextField {...params} variant="outlined" />}
      classes={{
        root: classes.root,
        paper: classes.paper,
        listbox: classes.listbox,
        option: classes.option,
        inputRoot: classes.inputRoot,
        endAdornment: classes.endAdornment,
      }}
      data-testid="segments-select"
      ListboxProps={{
        ["data-testid"]: "segments-select-list",
      }}
    />
  );
}

type SegmentOption =
  | (NonNullable<
      NonNullable<GetCrawlSegmentsQuery["getCrawl"]>["crawlSegments"]
    >["edges"][number]["node"] & {
      isGenerating: boolean;
      isFailed: boolean;
      isAllUrls?: undefined;
      isManageSegments?: undefined;
    })
  | { isAllUrls: true; label: string; isManageSegments?: undefined }
  | { isManageSegments: true; label: string; isAllUrls?: undefined };

function sortCrawlSegments(a: SegmentOption, b: SegmentOption): number {
  if (a.isAllUrls || b.isManageSegments) return -1;
  if (b.isAllUrls || a.isManageSegments) return 1;

  if (!a.segment.group) return -1;
  if (!b.segment.group) return 1;

  const group1 = a.segment.group;
  const group2 = b.segment.group;
  const groupCompare = group1
    .toLocaleLowerCase()
    .localeCompare(group2.toLocaleLowerCase());
  if (groupCompare !== 0) return groupCompare;

  const createdAt1 = a.segment.createdAt
    ? new Date(a.segment.createdAt).getTime()
    : 0;
  const createdAt2 = b.segment.createdAt
    ? new Date(b.segment.createdAt).getTime()
    : 0;
  return createdAt2 - createdAt1;
}
